import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (password === "0521") { // Replace with actual password
      navigate("/add-questions");
    } else {
      alert("Incorrect password!");
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Admin Login</h1>
      <input
        type="password"
        placeholder="Enter Admin Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ padding: "10px", width: "80%" }}
      />
      <br /><br />
      <button onClick={handleLogin} style={{ padding: "10px 20px", fontSize: "18px" }}>
        Login
      </button>
    </div>
  );
};

export default AdminLogin;
