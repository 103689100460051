import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QuizContext } from "../context/QuizContext";

const AddQuestions = () => {
  const { questions, setQuestions } = useContext(QuizContext);
  const navigate = useNavigate();
  const [questionsList, setQuestionsList] = useState([
    { question: "", type: "mcq", options: ["", "", "", ""], answer: "", category: "" }
  ]);
  const [showQuestions, setShowQuestions] = useState(false);

  // Load saved questions when component mounts
  useEffect(() => {
    const savedQuestions = localStorage.getItem("questions");
    if (savedQuestions) {
      setQuestions(JSON.parse(savedQuestions));
    }
  }, [setQuestions]);

  // Get unique categories from existing questions
  const getCategories = () => {
    const categories = new Set();
    questions.forEach((q) => categories.add(q.category));
    return Array.from(categories);
  };

  // Handle changes for question fields
  const handleChange = (index, field, value) => {
    const updatedQuestions = [...questionsList];
    updatedQuestions[index][field] = value;
    setQuestionsList(updatedQuestions);
  };

  // Handle changes for MCQ options
  const handleOptionChange = (qIndex, optIndex, value) => {
    const updatedQuestions = [...questionsList];
    updatedQuestions[qIndex].options[optIndex] = value;
    setQuestionsList(updatedQuestions);
  };

  // Add another empty question
  const addNewQuestion = () => {
    setQuestionsList([
      ...questionsList,
      { question: "", type: "mcq", options: ["", "", "", ""], answer: "", category: "" }
    ]);
  };

  // Save all entered questions
  const saveQuestions = () => {
    const validQuestions = questionsList.filter(q => q.question.trim() !== "" && q.answer.trim() !== "");
    if (validQuestions.length === 0) {
      alert("⚠️ Please enter at least one valid question!");
      return;
    }

    // Load existing questions from localStorage
    const existingQuestions = JSON.parse(localStorage.getItem("questions")) || [];

    // Merge new questions with existing ones
    const newQuestions = [...existingQuestions, ...validQuestions];

    setQuestions(newQuestions); // Update context
    localStorage.setItem("questions", JSON.stringify(newQuestions)); // Persist to localStorage

    alert(`✅ ${validQuestions.length} question(s) added successfully!`);

    setQuestionsList([{ question: "", type: "mcq", options: ["", "", "", ""], answer: "", category: "" }]); // Reset form
  };

  // Function to group questions by category
  const getQuestionsByCategory = () => {
    const groupedQuestions = {};
    questions.forEach((q) => {
      if (!groupedQuestions[q.category]) {
        groupedQuestions[q.category] = [];
      }
      groupedQuestions[q.category].push(q);
    });
    return groupedQuestions;
  };

  // Function to delete a question
  const deleteQuestion = (questionToDelete) => {
    const updatedQuestions = questions.filter(q => q !== questionToDelete);
    setQuestions(updatedQuestions);
    localStorage.setItem("questions", JSON.stringify(updatedQuestions));
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Add Multiple Questions</h1>

      {questionsList.map((q, index) => (
        <div key={index} style={{ borderBottom: "2px solid #ccc", paddingBottom: "20px", marginBottom: "20px" }}>
          <input
            type="text"
            placeholder="Enter Question"
            value={q.question}
            onChange={(e) => handleChange(index, "question", e.target.value)}
            style={{ padding: "10px", width: "80%", margin: "5px" }}
          />

          <label> Type: </label>
          <select value={q.type} onChange={(e) => handleChange(index, "type", e.target.value)} style={{ padding: "5px" }}>
            <option value="mcq">Multiple Choice</option>
            <option value="short-answer">Short Answer</option>
            <option value="true-false">True/False</option>
          </select>

          <br />

          {/* Category Dropdown */}
          <label> Category: </label>
          <select
            value={q.category}
            onChange={(e) => handleChange(index, "category", e.target.value)}
            style={{ padding: "10px", fontSize: "16px", margin: "10px" }}
          >
            <option value="">-- Select a Category --</option>
            {getCategories().map((category, i) => (
              <option key={i} value={category}>{category}</option>
            ))}
            <option value="new">➕ New Category</option>
          </select>

          {/* Show input if "New Category" is selected */}
          {q.category === "new" && (
            <input
              type="text"
              placeholder="Enter new category"
              onChange={(e) => handleChange(index, "category", e.target.value)}
              style={{ padding: "10px", width: "80%", margin: "5px" }}
            />
          )}

          {/* Show options if MCQ */}
          {q.type === "mcq" && (
            <div>
              <h4>Options:</h4>
              {q.options.map((opt, optIndex) => (
                <input
                  key={optIndex}
                  type="text"
                  placeholder={`Option ${optIndex + 1}`}
                  value={opt}
                  onChange={(e) => handleOptionChange(index, optIndex, e.target.value)}
                  style={{ padding: "10px", width: "40%", margin: "5px" }}
                />
              ))}
            </div>
          )}

          {/* Answer input */}
          <input
            type="text"
            placeholder="Correct Answer"
            value={q.answer}
            onChange={(e) => handleChange(index, "answer", e.target.value)}
            style={{ padding: "10px", width: "80%", margin: "5px" }}
          />
        </div>
      ))}

      {/* Buttons */}
      <button onClick={addNewQuestion} style={{ padding: "10px 20px", fontSize: "18px", margin: "10px" }}>
        ➕ Add Another Question
      </button>
      <button onClick={saveQuestions} style={{ padding: "10px 20px", fontSize: "18px", margin: "10px", backgroundColor: "green", color: "white" }}>
        ✅ Save Questions
      </button>
      <button onClick={() => setShowQuestions(!showQuestions)} style={{ padding: "10px 20px", fontSize: "18px", margin: "10px", backgroundColor: "orange", color: "white" }}>
        📋 {showQuestions ? "Hide" : "View"} All Questions
      </button>
      <button onClick={() => navigate("/")} style={{ padding: "10px 20px", fontSize: "18px", margin: "10px", backgroundColor: "blue", color: "white" }}>
        🔙 Back to Start
      </button>
    </div>
  );
};

export default AddQuestions;
