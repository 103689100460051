import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Results = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { finalScore, totalQuestions } = location.state || { finalScore: 0, totalQuestions: 0 };

  axios.post("http://localhost:5000/send-results", { score: finalScore, totalQuestions })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Quiz Completed!</h1>
      <h2>Your Score: {finalScore} / {totalQuestions}</h2>
      <button onClick={() => navigate("/")}>Try Again</button>
    </div>
  );
};

export default Results;
