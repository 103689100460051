import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QuizContext } from "../context/QuizContext";

const Quiz = () => {
  const { questions } = useContext(QuizContext);
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [score, setScore] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");

  // Load selected category from localStorage
  useEffect(() => {
    const savedCategory = localStorage.getItem("selectedCategory");
    if (savedCategory) {
      setSelectedCategory(savedCategory);
    }
  }, []);

  // Filter questions by selected category
  const filteredQuestions = questions.filter(q => q.category === selectedCategory);

  useEffect(() => {
    console.log("📌 Selected Category:", selectedCategory);
    console.log("📌 Filtered Questions:", filteredQuestions);
  }, [selectedCategory, filteredQuestions]);

  // If no questions match the selected category
  if (filteredQuestions.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2>No questions available for "{selectedCategory}" category!</h2>
        <button onClick={() => navigate("/")} style={{ padding: "10px 20px", fontSize: "18px", marginTop: "10px" }}>
          Go Back
        </button>
      </div>
    );
  }

  const currentQuestion = filteredQuestions[currentQuestionIndex];

  // Handle answer submission
  const handleAnswer = (selectedAnswer) => {
    if (selectedAnswer.trim().toLowerCase() === currentQuestion.answer.trim().toLowerCase()) {
      setScore(score + 1);
    }

    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < filteredQuestions.length) {
      setCurrentQuestionIndex(nextIndex);
      setUserAnswer("");
    } else {
      navigate("/results", { state: { finalScore: score + 1, totalQuestions: filteredQuestions.length } });
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Quiz: {selectedCategory}</h1>
      <h2>{currentQuestion.question}</h2>

      {/* Multiple Choice (MCQ) */}
      {currentQuestion.type === "mcq" && (
        <div>
          {currentQuestion.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswer(option)}
              style={{ padding: "10px 20px", fontSize: "18px", margin: "5px" }}
            >
              {option}
            </button>
          ))}
        </div>
      )}

      {/* Short Answer */}
      {currentQuestion.type === "short-answer" && (
        <div>
          <input
            type="text"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            placeholder="Type your answer..."
            style={{ padding: "10px", width: "80%", margin: "10px" }}
          />
          <br />
          <button
            onClick={() => handleAnswer(userAnswer)}
            style={{ padding: "10px 20px", fontSize: "18px", margin: "5px" }}
          >
            Submit Answer
          </button>
        </div>
      )}

      {/* True/False */}
      {currentQuestion.type === "true-false" && (
        <div>
          <button
            onClick={() => handleAnswer("True")}
            style={{ padding: "10px 20px", fontSize: "18px", margin: "5px" }}
          >
            True
          </button>
          <button
            onClick={() => handleAnswer("False")}
            style={{ padding: "10px 20px", fontSize: "18px", margin: "5px" }}
          >
            False
          </button>
        </div>
      )}
    </div>
  );
};

export default Quiz;
