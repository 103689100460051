import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Quiz from "./components/Quiz";
import Results from "./components/Results";
import AddQuestions from "./pages/AddQuestions";
import AdminLogin from "./pages/AdminLogin";
import QuizLogin from "./pages/QuizLogin";
import { QuizProvider } from "./context/QuizContext";

function App() {
  return (
    <QuizProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quiz-login" element={<QuizLogin />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/results" element={<Results />} />
          <Route path="/add-questions" element={<AddQuestions />} />
          <Route path="/admin" element={<AdminLogin />} />
          </Routes>
      </Router>
    </QuizProvider>
  );
}

export default App;
