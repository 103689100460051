import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleStartQuiz = () => {
    if (!selectedCategory) {
      alert("⚠️ Please select a category!");
      return;
    }

    localStorage.setItem("selectedCategory", selectedCategory);
    navigate("/quiz");
  };

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Welcome to the Study Quiz!</h1>

      {/* Category Selection Dropdown */}
      <label>Select Quiz Category: </label>
      <select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        style={{ padding: "10px", fontSize: "16px", margin: "10px" }}
      >
        <option value="">-- Select a Category --</option>
        <option value="Math">Math</option>
        <option value="Science">Science</option>
        <option value="History">History</option>
      </select>

      <br />
      <button onClick={handleStartQuiz} style={{ padding: "10px 20px", fontSize: "18px", marginTop: "10px" }}>
        Start Quiz
      </button>
    </div>
  );
};

export default Home;
