import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const QuizLogin = () => {
  const [password, setPassword] = useState(""); // State to store password input
  const navigate = useNavigate(); // Use navigate to redirect

  // Handle the password submission
  const handleLogin = () => {
    console.log("Entered password:", password);

    // Check if the password is correct
    if (password === "71") {
      console.log("Password correct, navigating to quiz...");
      navigate("/quiz"); // Navigate to the quiz page if the password is correct
    } else {
      console.log("Incorrect password.");
      alert("Incorrect password! Please try again."); // Show alert if password is incorrect
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Enter Quiz Password</h1>
      <input
        type="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)} // Update password state
        style={{ padding: "10px", width: "80%" }}
      />
      <br /><br />
      <button onClick={handleLogin} style={{ padding: "10px 20px", fontSize: "18px" }}>
        Enter Quiz
      </button>
    </div>
  );
};

export default QuizLogin;
